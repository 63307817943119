import React from 'react';

import { useUrlQueryParams } from 'hooks/useUrlQueryParams';

import { useLogout } from './useLogout';

export const Logout = () => {
    const urlQueryParams = useUrlQueryParams();
    const logoutId = urlQueryParams.get('logoutId');
    const [loading, loggedOut, onLogout, postLogoutRedirectUri] = useLogout(logoutId);

    return (
        <div className="tw-h-full tw-flex tw-flex-col tw-gap-12 tw-items-center">
            {!loading ? (
                <>
                    {loggedOut ? (
                        <>
                            <p className="tw-text-xl tw-font-bold">You are now logged out</p>
                            <a
                                href={postLogoutRedirectUri}
                                className="tw-p-12 tw-rounded tw-border tw-text-primary dark:tw-text-primary-dark hover:tw-text-primary-hover dark:hover:tw-text-primary-dark-hover"
                            >
                                Return to the SalesScreen application
                            </a>
                        </>
                    ) : (
                        <>
                            <p className="tw-text-xl tw-font-bold">
                                Would you like to logout of SalesScreen?
                            </p>
                            <a
                                onClick={onLogout}
                                className="tw-p-12 tw-rounded tw-border tw-text-primary dark:tw-text-primary-dark hover:tw-text-primary-hover dark:hover:tw-text-primary-dark-hover"
                            >
                                Logout
                            </a>
                        </>
                    )}
                </>
            ) : (
                <p> Loading ... </p>
            )}
        </div>
    );
};
