import 'vite/modulepreload-polyfill';
import 'styles/tailwind.css';

import React from 'react';
import ReactDOM from 'react-dom';

import { initRaygun } from 'helpers/tracking/ErrorTracking';

import App from './appWrapper';

initRaygun(['Login']);

ReactDOM.render(<App />, document.getElementById('loginComponentWrapper'));
