import React, { useEffect, useState } from 'react';

import { blobUrl } from 'constants/Common';

import { get, makeUrl } from 'apiutils';
import Carousel from 'nuka-carousel';
import { useInView } from 'react-intersection-observer';

import { Icon } from 'components/icons/Icon';

type ContentResponse = {
    content: SanityContentElement[];
};

type SanityContentElement = {
    category: ContentCategory;
    image: string;
    link: string;
    linkText: string;
    paragraph: string;
    title: string;
    videoUrl: string;
};

export enum ContentCategory {
    BLOG = 'blog',
    UPDATES = 'updates',
    WEBINARS = 'webinars',
    PRODUCT = 'product',
}

export const LoginPageContent = () => {
    const [singleContentElement, setSingleContentElement] = useState(false);
    const [loginPageContent, setContent] = useState<ContentResponse>(null);
    const [imageLoadCount, setImageLoadCount] = useState<number>(null);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const [ref, inView] = useInView({
        threshold: 1,
    });

    const getContent = () => {
        get(makeUrl('CmsContent', 'GetLoginContent'))
            .then((res) => {
                const response = res.body as ContentResponse;
                setContent(response);
                if (response.content.length === 1) {
                    setSingleContentElement(true);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        if (
            loginPageContent &&
            imageLoadCount === loginPageContent.content.filter((x) => x.image).length
        ) {
            setImagesLoaded(true);
        }
    }, [loginPageContent, imageLoadCount]);

    useEffect(() => {
        if (inView && !loginPageContent) {
            getContent();
        }
    }, [inView, loginPageContent]);

    if (!loginPageContent) {
        return (
            <img
                ref={ref}
                src={`${blobUrl}common/logos/inverted-horizontal.png`}
                alt="SalesScreen Logo"
                className="tw-w-[200px]"
            />
        );
    }

    return (
        <div className="2xl:tw-px-48 tw-px-16 tw-w-full tw-group" ref={ref}>
            {loginPageContent.content?.map((sanityContentElement, index) => {
                const { image, title } = sanityContentElement;
                return (
                    <img
                        key={index}
                        src={image ? image : `${blobUrl}common/logos/inverted-horizontal.png`}
                        alt={title}
                        onLoad={() => setImageLoadCount(imageLoadCount + 1)}
                        className="tw-hidden"
                    />
                );
            })}
            {imagesLoaded && (
                <Carousel
                    wrapAround={true}
                    animation="fade"
                    autoplay={true}
                    autoplayInterval={7000}
                    defaultControlsConfig={{
                        pagingDotsClassName: `${singleContentElement ? 'tw-hidden' : ''}`,
                        pagingDotsStyle: { fill: '#1eafaa' },
                        nextButtonClassName: `tw-opacity-0 lg:group-hover:tw-opacity-100 tw-transition-opacity tw-rounded tw-border-neutral tw-border tw-bg-primary-active tw-tracking-xx-loose disabled:tw-border-none tw-absolute -tw-right-[28px] tw-px-8 tw-py-4 ${
                            singleContentElement ? 'tw-hidden' : ''
                        }`,
                        nextButtonText: '>',
                        prevButtonText: '<',
                        nextButtonStyle: { border: '1px solid' },
                        prevButtonClassName: `tw-opacity-0 lg:group-hover:tw-opacity-100 tw-transition-opacity tw-rounded tw-border-neutral tw-border tw-bg-primary-active tw-tracking-xx-loose disabled:tw-border-none tw-absolute -tw-left-[28px] tw-px-8 tw-py-4 ${
                            singleContentElement ? 'tw-hidden' : ''
                        }`,
                        prevButtonStyle: { border: '1px solid' },
                    }}
                    dragging={false}
                    pauseOnHover={true}
                >
                    {loginPageContent.content?.map((sanityContentElement, index) => {
                        const { link, title, paragraph, image, category, linkText, videoUrl } =
                            sanityContentElement;
                        return (
                            <div
                                key={index}
                                className="tw-flex tw-items-center tw-flex-col tw-gap-12 tw-py-48"
                            >
                                <div className="tw-max-w-[848px] tw-w-full tw-px-24">
                                    <div className="tw-text-neutral-inverse tw-w-full">
                                        <div className="tw-font-medium tw-rounded tw-inline-block tw-px-12 tw-py-4 tw-tracking-xx-loose tw-border">
                                            {category.substring(0, 1).toUpperCase() +
                                                category.substring(1)}
                                        </div>
                                        <a
                                            href={link}
                                            target="__blank"
                                            rel="noopener noreferrer"
                                            className="tw-text-neutral-inverse hover:tw-text-neutral-dark"
                                        >
                                            <h2 className="lg:tw-text-[36px] tw-text-[26px] tw-font-medium tw-leading-xx-tight tw-mb-8 tw-mt-12">
                                                {title}
                                            </h2>
                                        </a>
                                        <p className="lg:tw-text-xl tw-text-neutral-inverse/80">
                                            {paragraph}
                                        </p>
                                        <a
                                            className="lg:tw-text-xl tw-font-medium tw-flex tw-items-center tw-gap-4 tw-mt-16 hover:tw-opacity-80 tw-text-[#86d9d6] hover:tw-text-[#86d9d6] tw-group/link"
                                            href={link}
                                            target="__blank"
                                            rel="noopener noreferrer"
                                        >
                                            {linkText}
                                            <span className="group-hover/link:tw-translate-x-4 tw-transition-all">
                                                <Icon icon="arrow2-right" size="small" />
                                            </span>
                                        </a>
                                    </div>

                                    {videoUrl ? (
                                        <video
                                            autoPlay
                                            loop
                                            muted
                                            playsInline
                                            className="tw-mt-16 tw-w-full"
                                        >
                                            <source src={videoUrl} type="video/mp4" />
                                            <p>
                                                Your browser doesn't support HTML5 video. Here is a{' '}
                                                <a href={videoUrl}>link to the video</a> instead.
                                            </p>
                                        </video>
                                    ) : (
                                        <a href={link} target="__blank" rel="noopener noreferrer">
                                            <img
                                                className="tw-w-full tw-rounded tw-mt-16"
                                                src={image}
                                                alt={title}
                                            />
                                        </a>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </Carousel>
            )}
        </div>
    );
};
